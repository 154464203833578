import type CachingEntry from './CachingEntry.js'

export default function buildTimingHeader(entries: CachingEntry[]) {
  const contents = btoa(JSON.stringify(entries.map(({ path, ttl, etag, expires, source, initiator }) => [
    path,
    ttl,
    etag,
    expires,
    source,
    initiator,
  ])))

  return `dxcache;desc="${contents}"`
}