/**
 * If condition is truthy, return `invoke(consequent)`. Otherwise return `invoke(alternative)`.
 * This is very useful shorthand in places where numerous if statements would make
 * code less readable.
 *
 * @example
 * when(true, 'foo', 'bar') // 'foo'
 * when(false, 'foo', 'bar') // 'bar'
 * when(true, 'foo') // 'foo'
 * when(false, 'foo') // undefined
 * when(true, () => 'hello!') // 'hello'
 *
 * // Usage scenarios:
 *
 * <button onClick={when(isEnabled, handleClick)}>Click me!</button>
 * // Replaces the following; React would also complain that `false` was passed
 * <button onClick={isEnabled && handleClick}>Click me!</button>
 *
 *
 * const user = await when(id, () => db.find('User', id))
 * // Replaces...
 *
 * let user
 * if (id) {
 *   user = await db.find('User', id)
 * }
 *
 * or...
 *
 * const user = id ? (await db.find('User', id)) : undefined
 */

export function when<C extends Falsy, T, X = undefined>(condition: C, consequent: T, alternative?: X): (IsNever<Extract<C, Falsy>> extends true ? never : Invoke<X>) | undefined
export function when<C, T, X = undefined>(condition: Truthy<C>, consequent: T, alternative?: X): (IsNever<Extract<C, Falsy>> extends true ? Invoke<T> : never) | undefined
export function when<C, T, X = undefined>(condition: C, consequent: T, alternative?: X): Invoke<X> | Invoke<T> | undefined
export function when(condition: any, consequent: any, alternative: any) {
  return condition ? invoke(consequent) : invoke(alternative)
}
