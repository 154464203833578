/**
 * If `key` is not set, set it using the value returned by invoking `fn`. Always returns the value at `key`.
 */
export function ensure<K, V>(this: Map<K, V>, key: K, fn: () => NoInfer<V>) {
  if (!this.has(key)) {
    const value = fn()
    this.set(key, value)
    return value
  }

  return this.get(key)!
}

/*
If `key` is set it is deleted from the map and the associated value is returned.
 */
export function pull<K, V>(this: Map<K, V>, key: K) {
  const value = this.get(key)

  if (value) {
    this.delete(key)
    return value
  }
}

declare global {
  interface Map<K, V> { // eslint-disable-line @typescript-eslint/no-unused-vars
    ensure: typeof ensure
    pull: typeof pull
  }
}