import {
  future,
  toAsyncIterator,
} from '#/promise'

/**
 * Determines if value is a Promise, or more precisely, a "thenable".
 */
export function isPromise<T>(value: T | Promise<T>): value is Promise<T> {
  return value && (typeof value === 'object' || typeof value === 'function') && typeof (value as any).then === 'function'
}

/**
 * Iterates over elements of array in parallel, invoking iteratee for each element.
 */
export async function each<Item>(array: Item[], iteratee: (item: Item) => unknown): Promise<void> {
  await Promise.all(array.map(iteratee))
}

/**
 * Iterates over elements of array in series, invoking iteratee for each element.
 */
export async function eachSeries<Item>(array: Item[], iteratee: (item: Item) => unknown): Promise<void> {
  for (const work of array) {
    await iteratee(work)
  }
}

/**
 * Iterates over elements of array in parallel, invoking iteratee for each element.
 * The resulting array will be flattened one level.
 */
export async function flatMap<Result, Item>(array: Item[] | readonly Item[], iteratee: (item: Item, index: number) => Result | Promise<Result>): Promise<Array<Result extends readonly (infer InnerArr)[] ? InnerArr : Result>> {
  const mapped = await map(array, iteratee)
  return mapped.flat()
}

/**
 * Iterates over elements of array in parallel, invoking predicate for each element,
 * and returns only those elements the predicate returns a truthy value for.
 */
export async function filter<Item>(array: Item[] | readonly Item[], iteratee: (item: Item, index: number) => unknown): Promise<Exclude<Item, Falsy>[]> {
  const results = await map(array, async (item, index) => [
    item,
    await iteratee(item, index),
  ] as const)

  return results
    .filter(item => item[1])
    .map(item => item[0]) as Exclude<Item, Falsy>[]
}

/**
 * Iterates over elements of array in parallel, invoking predicate for each
 * element, and returns only those elements the predicate returns a truthy value for.
 */
export async function filterMap<Result, Item>(array: Item[] | readonly Item[], iteratee: (item: Item, index: number) => Promise<Result> | Result): Promise<Exclude<Result, false | '' | Nullish>[]> {
  const result = await map(array, iteratee)
  return result.compact()
}


/**
 * Iterates over elements of array in parallel, invoking iteratee for each element.
 */
export function map<Result, Item>(array: Item[] | readonly Item[], iteratee: (item: Item, index: number) => Promise<Result> | Result): Promise<Result[]> {
  return Promise.all(array.map(iteratee))
}

/**
 * Iterates over elements of array in series, invoking iteratee for each element.
 */
export async function mapSeries<Result, Item>(array: Item[] | readonly Item[], iteratee: (item: Item, index: number) => Result | Promise<Result>): Promise<Awaited<Result>[]> {
  const results: Awaited<Result>[] = []

  for (const [index, work] of array.entries()) {
    results.push(await iteratee(work, index))
  }
  
  return results
}
 
/**
 * Reduces array to a single value by invoking iteratee in series for each
 * element and accumulating the resulting values. The return value of the
 * previous iteration is provided as the second argument to iteratee.
 */
export function reduce<Result>(array: Result[] | readonly Result[], iteratee: (item: Result, ...args: any[]) => Promise<Result>, initial?: Result) {
  const startIndex = initial === undefined ? 0 : 1
  let current = Promise.resolve(initial ?? array[0])

  for (let i = startIndex; i < array.length; i++) {
    const item = array[i]
    current = current.then(next => iteratee(next, item) as Awaited<Result>)
  }

  return current
}

export {
  future,
  toAsyncIterator,
}

declare global {
  interface PromiseConstructor {
    each: typeof each
    eachSeries: typeof eachSeries
    filter: typeof filter
    filterMap: typeof filterMap
    flatMap: typeof flatMap
    future: typeof future
    toAsyncIterator: typeof toAsyncIterator
    isPromise: typeof isPromise
    map: typeof map
    mapSeries: typeof mapSeries
    reduce: typeof reduce
  }
}