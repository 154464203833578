export const _ = Object.preventExtensions(Object.freeze([]))

/**
 * Cast a value to an array, if it isn't already one.
 */
export function cast<T>(value?: null | T | T[] | readonly T[]): T[] {
  if (value == null) {
    return [] as T[]
  }

  if (Array.isArray(value)) {
    return value as T[]
  }

  if (value instanceof Set || value instanceof Map) {
    return Array.from(value) as T[]
  }

  return [value] as T[]
}


declare global {
  interface ArrayConstructor {
    _: [],
    cast: typeof cast
    isArray<V extends readonly V[]>(value: any): value is V
  }
}
