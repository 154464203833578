import { flow } from 'lodash-es'

type OptionalFn = UnknownFunction | Compactable

type Tail<T extends unknown[]> = ((...args: T) => void) extends ((first: unknown, ...rest: infer U) => void) ? U : never
type LastIndexOf<T extends unknown[]> = Tail<T> extends unknown[] ? Tail<T>['length'] : never

type ValidateChain<I, F extends OptionalFn[]> = F extends [infer H, ...unknown[]] ?
  H extends UnknownFunction ?
    I extends Parameters<H>[0] ?
      ValidateChain<ReturnType<H>, Tail<F>> :
      false :
    ValidateChain<I, Tail<F>> :
  true

type FirstArgType<F extends OptionalFn[]> = F[0] extends UnknownFunction ?
  Parameters<F[0]>[0] :
  F extends [unknown, ...infer U] ?
    U extends OptionalFn[] ?
      FirstArgType<U> :
      never :
    unknown

type LastReturnType<F extends OptionalFn[]> = F[LastIndexOf<F>] extends UnknownFunction ?
  ReturnType<F[LastIndexOf<F>]> :
  F extends [...infer U, unknown] ?
    U extends OptionalFn[] ?
      LastReturnType<U> :
      never :
    unknown

type Compose<F extends OptionalFn[]> = ValidateChain<FirstArgType<F>, F> extends true ?
  (arg: FirstArgType<F>) => LastReturnType<F> :
  FunctionsAreNotComposable

type FunctionsAreNotComposable = {
  readonly type: unique symbol
}


// Creates a function that returns the result of invoking the given functions
// with the `this` binding of the created function, where each successive
// invocation is supplied the return value of the previous.
export function compose<F extends OptionalFn[]>(...f: F): Compose<F> {
  return flow(...f.compact())
}
