import { memoize as _memoize } from 'lodash-es'



const resolveCacheKey = cacheKeyResolver()

/**
 * Creates a new function that memoizes the result of `fn`. Uses
 * `lodash.memoize` under the hood, with a special cache key generator that
 * operates on all arguments, including functional arguments.
 *
 * @example
 * let count = 0
 *
 * const fn = value => `${count++}: ${value}`
 * const memoized = memoize(fn)
 *
 * memoized('foo') // `0: foo`
 * memoized('foo') // `0: foo`
 * memoized('bar') // `1: bar`
 * memoized('bar') // `1: bar`
 * memoized('baz') // `2: baz`
 * memoized('foo') // `0: foo`
 */
export function memoize<F extends UnknownFunction>(fn: F) {
  const memoized_ = _memoize(fn, resolveCacheKey)
  const memoized = memoized_ as unknown as F & { reset: () => void }
  memoized.reset = () => memoized_.cache.delete(fn)
  return memoized
}


function cacheKeyResolver() {
  let count = 0
  const weakMap = new WeakMap()

  function replacer(_key: any, value: any): any {
    if (typeof value === 'function') {
      let id = weakMap.get(value)

      if (!id) {
        id = `__function__${count++}__`
        weakMap.set(value, id)
      }

      return id
    }

    if (value instanceof Date) {
      return value.valueOf()
    }

    return value
  }

  return function (...values: any[]): string {
    return JSON.stringify(values, replacer)
  }
}