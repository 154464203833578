import * as arrayStatic from './array.js'
import * as arrayPrototype from './arrayPrototype.js'
import * as jsonStatic from './json.js'
import * as mapPrototype from './mapPrototype.js'
import * as mathStatic from './math.js'
import * as numberStatic from './number.js'
import * as objectStatic from './object.js'
import * as promiseStatic from './promise.js'
import * as regExpStatic from './regExp.js'
import * as setPrototype from './setPrototype.js'
import * as stringPrototype from './stringPrototype.js'
import * as weakMapPrototype from './weakMapPrototype.js'
import * as urlStatic from './url.js'

const extensions: [object, Record<string, any>][] = [
  [Array, arrayStatic],
  [Array.prototype, arrayPrototype],
  [JSON, jsonStatic],
  [Math, mathStatic],
  [Map.prototype, mapPrototype],
  [Number, numberStatic],
  [Object, objectStatic],
  [Promise, promiseStatic],
  [RegExp, regExpStatic],
  [Set.prototype, setPrototype],
  [String.prototype, stringPrototype],
  [WeakMap.prototype, weakMapPrototype],
  [WeakMap, urlStatic],
]

export default extensions