/**
 * A more useful String.matchAll.
 *
 * Repeatedly matches the regexp pattern against string and returns
 * the matches as an array. If `group` is a number, an array containing only that
 * capture group will be returned. If `group` is 'all', an array containing an
 * array of capture groups will be returned.
 *
 * @example
 * scan('abc 123 cde 4 f', /\d+/g) // ['123', '4']
 * scan('foo: 123, bar: 456', /([a-z]+): (\d+)/g, 'all') // [['foo', '123'], ['bar', '456']]
 * scan('foo: 123, bar: 456', /([a-z]+): (\d+)/g, 0) // ['foo', 'bar']
 * scan('foo: 123, bar: 456', /([a-z]+): (\d+)/g, 1) // ['123', '456']
 */
export function scan(string: string, pattern: RegExp, group: 'all'): string[][]
export function scan(string: string, pattern: RegExp, group?: number | string): string[]
export function scan(string: string, pattern: RegExp, group?: number | string) {
  pattern.lastIndex = 0
  const matches = Array.from(string.matchAll(pattern))

  if (typeof group === 'number') {
    return matches.map(m => m[group + 1])
  }

  if (group === 'all') {
    return matches.map(m => m.slice(1))
  }

  if (typeof group === 'string') {
    return matches.map(m => m.groups?.[group])
  }

  return matches.map(m => m[0])
}