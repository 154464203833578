import {
  random,
  round,
} from 'lodash-es'

/**
 * Restrict a number to the given range.
 */
export function clamp(number: number, minimum = -Infinity, maximum = Infinity) {
  return Math.min(Math.max(number, minimum), maximum)
}

/**
 * Produces a random integer. If only upper is provided, a number between 0 and upper is returned.
 */
export const randomInt = random

/**
 * Computes number rounded to precision.
 */
export const roundFloat = round

declare global {
  interface Math {
    clamp: typeof clamp
    randomInt: typeof randomInt
    roundFloat: typeof roundFloat
  }
}
