import {
  escapeRegExp,
} from 'lodash-es'

/**
 * Escapes the RegExp special characters in the provided string.
 */
export const escape = escapeRegExp


type UnionOptions = {
  edges?: string[]
  flags?: string
}

/**
 * Escapes each of the terms, wraps them in the optionally provided edges,
 * combines the terms together as an alternation, then creates a RegExp with flags.
 */
export function union(terms: string[], options: UnionOptions = {}) {
  const {
    edges = Array._,
    flags,
  } = options

  const edge = (e: string[]) => e.length > 1 ?
    `(?:${e.join('|')})` :
    ''

  const segments = terms.map(term => `${edge(['^', ...edges])}${term}${edge([...edges, '$'])}`)
  return new RegExp(segments.join('|'), flags)
}


declare global {
  interface RegExpConstructor {
    escape: typeof escape
    union: typeof union
  }
}
