/**
A function to output whatever single argument it is given using console.warn; it then returns that argument.

If two arguments are given, the first arugment is prefixed as label to the console statement.
*/
export function debug<T>(arg: T, ...args: any[]) {
  const props: (T | string)[] = [arg]

  if (args.length) {
    props.unshift(`[${args.pop()}]`)
  }

  console.warn(...props)
  return arg
}

export function fail(message: string): never {
  throw new Error(`AssertionError: ${message}`)
}

export function assert(condition: unknown, message: string): asserts condition {
  if (!condition) {
    throw new Error(`AssertionError: ${message}`)
  }
}

export function json(value: unknown) {
  return JSON.stringify(value, null, 2)
}

export function proxy(value: unknown) {
  return JSON.parse(JSON.stringify(value, null, 2))
}