export function ensure<K extends object, V>(this: WeakMap<K, V>, key: K, fn: () => NoInfer<V>) {
  if (!this.has(key)) {
    const value = fn()
    this.set(key, value)
    return value
  }

  return this.get(key)!
}

declare global {
  interface WeakMap<K, V> { // eslint-disable-line @typescript-eslint/no-unused-vars
    ensure: typeof ensure
  }
}
