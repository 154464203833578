import CachingEntry from './CachingEntry.js'

import type {
  CachingSource,
  CachingInitiator,
} from './types.js'


const pattern = regex`
^
(               # Optional server timing preamble
  dxcache;
  desc=
  (?<quote>")   # Quote
)
(?<timing>      # Group "timing"
  .+?
)
\k<quote>       # Match the quote if there was a preamble
`

export default function parseTimingHeader(header: Nullable<string>) {
  const match = header?.matchGroups(pattern)


  if (!match?.timing) {
    return []
  }

  try {
    const text = atob(match.timing)
    const json = JSON.tryParse(text)

    if (!Array.isArray(json)) {
      return []
    }

    return json.filterMap(entry => {
      if (Array.isArray(entry) && entry.length === 6) {
        const [
          path,
          ttl_,
          etag,
          expires,
          source,
          initiator,
        ] = entry as string[]

        return new CachingEntry({
          path,
          etag,
          ttl: Number.castInt(ttl_),
          expires: Number.castInt(expires),
          source: source as CachingSource,
          initiator: initiator as CachingInitiator,
        })
      }
    })

  } catch (e) {
    dx.capture(new Error(`Invalid server timing header: '${header}'`, { cause: e }))
    return []
  }
}