import safeParse_ from 'secure-json-parse'

export const safeParse = safeParse_

/**
 * Calls JSON.stringify and ignores any errors.
 */
export function safeStringify(...args: Parameters<typeof JSON.stringify>) {
  try {
    return JSON.stringify(...args)
    
  } catch (e) {
    return null
  }
}

/**
 * Uses secure-json-parse to prevent prototype poisoning when parsing and
 * ignores any parsing errors.
 */
export function tryParse(text: string | Buffer | Nullish) {
  try {
    if (text == null) {
      return null
    }

    return safeParse_(text) as unknown

  } catch (e) {
    return null
  }
}


declare global {
  interface JSON {
    tryParse: typeof tryParse
    safeParse: typeof safeParse_
    safeStringify: typeof safeStringify
  }
}