/* eslint-disable @typescript-eslint/no-floating-promises */

export type Future<T> = Promise<T> &
  {
    resolve: (value: T | PromiseLike<T>) => void
    reject: (reason?: unknown) => void
  } & (
    { status: 'pending', value: undefined, error: undefined } |
    { status: 'resolved', value: T, error: undefined } |
    { status: 'rejected', value: undefined, error: unknown }
  )

type FutureExecutor<T> = (resolve: Future<T>['resolve'], reject: Future<T>['reject']) => T | Promise<T> | void

/**
 * Returns a Promise-like object (a "thenable") with the resolve and reject
 * methods exposed. This can be useful when, in very rare circumstances, it is
 * impossible to resolve the promise from within the executor. Optionally takes
 * an executor fn just like a regular promise.
 */
export function future<T>(fn: FutureExecutor<T> = noop) {
  const { promise: promise_, resolve, reject } = Promise.withResolvers<T>()

  const promise = promise_ as Future<T>
  promise.resolve = resolve
  promise.reject = reject
  promise.status = 'pending'

  promise
    .then(value => {
      if (promise.status === 'pending') {
        Object.assign(promise, {
          status: 'resolved',
          value,
          error: undefined,
        })
      }

      return value
    })
    .catch((error: unknown) => {
      if (promise.status === 'pending') {
        Object.assign(promise, {
          status: 'rejected',
          value: undefined,
          error,
        })
      }
    })

  try {
    const result = fn(resolve, reject)
    Promise.resolve(result).catch(reject)

  } catch (e) {
    reject(e)
  }

  return promise
}




export function toAsyncIterator<T>(promises: Promise<T>[]): AsyncIterableIterator<T> {
  const queue = [...promises]

  return {
    [Symbol.asyncIterator]() {
      return this
    },

    async next() {
      if (queue.length > 0) {
        const [value, promise] = await Promise.race(queue.map(p => p.then(v => [v, p] as const)))
        queue.pull(promise)
        return { value, done: false }
      }

      return { value: undefined, done: true }
    },

    async return() {
      queue.length = 0
      return { done: true, value: undefined }
    },
  }
}