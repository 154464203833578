import EventEmitter from '#/EventEmitter'
import purge from './purge.js'
import buildTimingHeader from './buildTimingHeader.js'
import parseTimingHeader from './parseTimingHeader.js'
import { getFetchPath } from '#/fetch'

import CachingEntry, {
  type CachingEntryAttributes,
} from './CachingEntry.js'

import type {
  CachingEvents,
  CachingInitiator,
} from './types.js'

import {
  rootName,
  maxCachingEntries,
} from './constants.js'



type AppendOptions = {
  url?: RequestInfo | URL | false
  header: Nullable<string>
  initiator?: CachingInitiator
}


export default class CachingRegistry {
  #entries: CachingEntry[] = []
  #lastLength = 0
  #emitter = new EventEmitter<CachingEvents>()

  on: EventEmitter<CachingEvents>['on']
  off: EventEmitter<CachingEvents>['off']

  constructor() {
    this.on = this.#emitter.on.bind(this.#emitter)
    this.off = this.#emitter.off.bind(this.#emitter)
  }

  all() {
    return this.#entries
  }

  push(attrs: CachingEntryAttributes) {
    this.#update([
      new CachingEntry(attrs),
      ...this.#entries,
    ])
  }

  append({ url = false, header, initiator }: AppendOptions) {
    const path = url === false ? rootName : getFetchPath(url)

    this.#update([
      ...parseTimingHeader(header).map(entry => {
        if (entry.isRoot()) {
          entry.path = path
        }

        entry.initiator = initiator ?? entry.initiator
        return entry
      }),
      ...this.#entries,
    ])
  }

  async purgeOne(id: number) {
    const entry = this.#entries.find(e => e.id === id)
    await entry?.purge()
    this.#notify()
  }

  async purgeAll() {
    await purge()
    this.#entries.forEach(e => e.clear())
    this.#notify()
  }

  header() {
    return buildTimingHeader(this.#entries)
  }

  navigate() {
    this.#update(this.#entries.slice(0, this.#entries.length - this.#lastLength))
    this.mark()
  }

  mark() {
    this.#lastLength = this.#entries.length
  }

  #update(nextEntries: CachingEntry[]) {
    this.#entries = nextEntries
      .uniqBy('path')
      .slice(0, maxCachingEntries)

    this.#notify()
  }

  #notify() {
    this.#emitter.emit('update', this.#entries)
  }
}
