/**
 * Determines whether a value is considered "blank" or "empty".
 *
 * These include:
 * 
 * - NaN
 * - empty string
 * - string with only whitespace
 * - empty array
 * - plain objects with no properties
 */
export function isBlank<T>(value: T): value is Extract<T, Blank> {
  const type = typeof value

  if (type === 'boolean') {
    return false
  }

  if (typeof value === 'number') {
    // NaN
    return isNaN(value)
  }

  if (!value || (typeof value === 'string' && value.trim().length === 0)) {
    // null, undefined, empty string, whitespace-only string
    return true
  }

  if (value instanceof Set || value instanceof Map) {
    return value.size === 0
  }

  if (type === 'object') {
    if (Array.isArray(value) && value.length === 0) {
      // empty array
      return true
    }

    // eslint-disable-next-line no-unreachable-loop, guard-for-in
    for (const _ in value) {
      return false
    }

    if (Object.isObject(value)) {
      // empty object
      return true
    }
  }

  return false
}

/**
 * Determines whether a value is considered "present" or "non-blank".
 *
 * These include:
 * 
 * - false
 * - true
 * - numbers, including 0
 * - Dates
 * - Errors
 * - non-plain objects
 * - non-whitespace strings
 */
export function isPresent<T>(value: T): value is Exclude<T, Blank> {
  return !isBlank<T>(value)
}




type Coalesce<T> = T extends [] ?
  undefined :
  T extends [infer U, ...infer R] ?
    U extends Falsy ?
      Coalesce<R> :
      U :
    never

/**
 * Returns the first "present" argument as determined by `isPresent`.
 */
export function coalesce<T extends readonly unknown[]>(...values: T) {
  return values.find(v => v && isPresent(v)) as Coalesce<T>
}



/**
 * If `condition` is present, returns the result of invoking `T`.
 * Otherwise returns `undefined`.
 */
export function presence<C extends Blank, T, X = undefined>(condition: C, consequent: T, alternative?: X): (IsNever<Extract<C, Blank>> extends true ? never : Invoke<X>) | undefined
export function presence<C, T, X = undefined>(condition: Present<C>, consequent: T, alternative?: X): (IsNever<Extract<C, Blank>> extends true ? Invoke<T> : never) | undefined
export function presence<C, T, X = undefined>(condition: C, consequent: T, alternative?: X): Invoke<X> | Invoke<T> | undefined
export function presence(condition: any, consequent: any, alternative: any) {
  return is.present(condition) ?
    invoke(consequent) :
    invoke(alternative)
}
