import produce_ from '#/produce'
// import uuid_ from '#/uuid'

import {
  compose as compose_,
  invoke as invoke_,
  memoize as memoize_,
  when as when_,
} from '#/function/index'

import {
  fail,
  assert,
  json,
  proxy,
  debug,
} from '#/debug'

import {
  coalesce as coalesce_,
  presence as presence_,
} from '#/presence'

import * as is_ from '#/is'
import { regex as regex_, pattern as pattern_ } from 'regex'

import {
  identity as identity_,
  noop as noop_,
  stubFalse as noopFalse_,
  stubTrue as noopTrue_,
} from 'lodash-es'


;(regex_ as typeof regex).pattern = pattern_

export default function define() {
  Object.assign(globalThis, {
    __assert: assert,
    __debug: debug,
    __fail: fail,
    __json: json,
    __log: console.log,
    __proxy: proxy,
    __warn: console.warn,
    coalesce: coalesce_,
    compose: compose_,
    identity: identity_,
    invoke: invoke_,
    is: is_,
    memoize: memoize_,
    noop: noop_,
    noopFalse: noopFalse_,
    noopTrue: noopTrue_,
    presence: presence_,
    produce: produce_,
    regex: regex_,
    tupleize: <const T>(value: T[]) => value as Tupleize<T>,
    // uuid: uuid_,
    when: when_,
  })
}
