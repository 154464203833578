/**
 * Escapes each of the terms, wraps them in the optionally provided edges,
 * combines the terms together as an alternation, then creates a RegExp with flags.
 */
export function canParse(url: string | URL, base?: string | URL) {
  try {
    return Boolean(new URL(url, base))
  } catch (e) {
    return false
  }
}


declare global {
  interface URL {
    canParse: typeof canParse
  }
}
