import { isBlank, isPresent } from './presence.js'
import isToken from './ai/tokens/isToken.js'
import type { Coordinates } from '^/app/types/index'

export const array = <T = any>(v: T[]): v is T[] => Array.isArray(v)
export const bigint = (v: any): v is bigint => typeof v === 'bigint'
export const blank = isBlank
export const boolean = (v: any): v is boolean => typeof v === 'boolean'
export const date = (v: any): v is Date => v instanceof Date
export const dateTime = (v: any): v is Date => v instanceof Date
export const error = (v: any): v is Error => v instanceof Error
export const falsy = (v: any) => isBlank(v) || v === false
export const keyof = <T>(key: PropertyKey, object: T): key is keyof T => key in (object as any)
export const integer = (v: any): v is number => Number.isInteger(v)
export const jsonArray = (v: any): v is JsonArray => array(v)
export const nil = (v: any): v is null => v === null
export const number = (v: any): v is number => typeof v === 'number'
export const float = (v: any): v is number => number(v) && !Number.isInteger(v)
export const object = Object.isObject
export const json = (v: any): v is JsonObject | JsonArray => array(v) || object(v)
export const jsonObject = (v: any): v is JsonObject => object(v)
export const present = isPresent
export const string = (v: any): v is string => typeof v === 'string'
export const symbol = (v: any): v is symbol => typeof v === 'symbol'
export const propertyKey = (v: any): v is PropertyKey => string(v) || number(v) || symbol(v)
export const token = isToken
export const embedding = (v: any): v is number[] =>
  v &&
  Array.isArray(v) &&
  v.length === 1536 &&
  v.every(is.number)
  
export const coordinates = (v: any): v is Coordinates =>
  v &&
  Object.isObject(v) &&
  'latitude' in v &&
  'longitude' in v &&
  (is.number(v.latitude) || is.string(v.latitude)) &&
  (is.number(v.longitude) || is.string(v.longitude))


export const component = <P>(v: any, c: React.JSXElementConstructor<P>): v is React.ReactElement<P> =>
  React.isValidElement(v) && v.type === c