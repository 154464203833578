/**
 * Adds all arguments to the set, similar to Array.protoype.push.
 */
export function addAll<T, V>(this: Set<T>, ...values: V[]) {
  const set = this as Set<T | V>
  values.forEach(v => set.add(v))
  return set
}

/**
 * Deletes all arguments from the set.
 */
export function deleteAll<T>(this: Set<T>, ...values: T[]) {
  const set = this
  values.forEach(v => set.delete(v))
  return set
}

/**
 * Creates a new set containing only those values that exist exclusively in this, i.e. subtracts the values in other from this.
 */
export function difference<T, U>(this: Set<T>, other: Set<U | T>) {
  return new Set([...this].filter(i => !other.has(i)))
}

/**
 * Determines whether all members of the set satisfy predicate.
 */
export function every<T, S extends T>(this: Set<T>, predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: unknown): boolean {
  return Array.from(this).every(predicate, thisArg)
}

/**
 * Returns the elements of the set that meet the condition specified in the callback function.
 */
export function filter<T, S extends T>(this: Set<T>, predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: unknown): S[] {
  return Array.from(this).filter(predicate, thisArg)
}

/**
 * Returns the value of the first element in the array where the predicate is true.
 */
export function find<T, S extends T>(this: Set<T>, predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: unknown): S | undefined {
  return Array.from(this).find(predicate, thisArg)
}

/**
 * Returns true if this set has all members of the other set.
 */
export function hasAll<T, U>(this: Set<T>, other: Set<U | T>) {
  return this.size >= other.size && other.difference(this).size === 0
}

/**
 * Creates a new set containing only those values that exist in both this and other. Commutative.
 */
export function intersection<T, U>(this: Set<T>, other: Set<U | T>) {
  return new Set([...this].filter(i => other.has(i)))
}

/**
 * Returns whether the sets have the same size and the same members.
 */
export function isEqual<T, U>(this: Set<T>, other: Set<U>) {
  return this.size === other.size && this.difference(other).size === 0
}

/**
 * Calls the callback function for each element of the set and returns an array that contains the results.
 */
export function map<T, U>(this: Set<T>, callbackFn: (value: T, index: number, array: T[]) => U, thisArg?: unknown): U[] {
  return Array.from(this).map(callbackFn, thisArg)
}

/*
 * Returns true if this set intersects with the other set.
 */
export function overlaps(this: Set<unknown>, other: Set<unknown>) {
  return this.intersection(other).size > 0
}

/**
 * Determines whether at leaast one member of the set satisfies the specified test.
 */
export function some<T>(this: Set<T>, predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: unknown): boolean {
  return Array.from(this).some(predicate, thisArg)
}

/**
 * If the argument is in the set, it is removed, otherwise it is added.
 */
export function toggle<T, V>(this: Set<T>, value: V) {
  const set = this as Set<T | V>

  set.has(value) ?
    set.delete(value) :
    set.add(value)

  return set
}

/**
 * Creates a new set containing all values in both this and other. Commutative.
 */
export function union<T, U>(this: Set<T>, other: Set<U>) {
  return new Set([...this, ...other])
}



declare global {
  interface Set<T> { // eslint-disable-line @typescript-eslint/no-unused-vars
    addAll: typeof addAll
    deleteAll: typeof deleteAll
    every: typeof every
    filter: typeof filter
    find: typeof find
    hasAll: typeof hasAll
    overlaps: typeof overlaps
    isEqual: typeof isEqual
    map: typeof map
    some: typeof some
    toggle: typeof toggle
  }
}