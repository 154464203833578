import {
  chunk as chunk_,
  groupBy as groupBy_,
  identity,
  maxBy as maxBy_,
  minBy as minBy_,
  orderBy as orderBy_,
  partition as partition_,
  property as property_,
  pull as pull_,
  sample as sample_,
  sampleSize as sampleSize_,
  shuffle as shuffle_,
  sortBy as sortBy_,
  uniq as uniq_,
  uniqBy as uniqBy_,
  without as without_,
  zip as zip_,
  zipObject as zipObject_,
} from 'lodash-es'

type IterateeFn<T, R> = (item: T, index: number) => R
type Iteratee<T, R, P extends PropertyKey = PropertyKey> = P | IterateeFn<T, R>

type ExpandPath<T, P> = P extends keyof T ?
  T[P] :
  P extends `${infer L}.${infer R}` ?
    L extends keyof T ?
      ExpandPath<T[L], R> :
      undefined :
    undefined


function _iteratee<T, P extends PropertyKey>(input: P): IterateeFn<T, ExpandPath<T, P>>
function _iteratee<T, R>(input: IterateeFn<T, R>): IterateeFn<T, R>
function _iteratee<T>(input: any) {
  if (typeof input === 'string' || typeof input === 'number' || typeof input === 'symbol') {
    return property_<T, ExpandPath<T, PropertyKey>>(input)
  }

  return input
}



/**
 * Creates an array of values split into groups of `size`.
 */
export function chunk<T>(this: List<T>, size = 1) {
  return chunk_(this, size)
}

/**
 * Returns a new array with all falsey values (except `0`) removed. The values
 * `false`, `null`, `""`, `undefined`, and `NaN` are falsey.
 */
export function compact<T>(this: List<T>) {
  return this.filter(i => i || i === 0) as Exclude<T, Compactable>[]
}

/**
 * Invokes iteratee for each element in the array and returns a count of the
 * number of times the iteratee returns a truthy value.
 */
export function countOf<T>(this: List<T>, iteratee: Iteratee<T, any>) {
  const fn = _iteratee<T, any>(iteratee)
  return (this as T[]).reduce((total, item, index: number) => total + (fn(item, index) ? 1 : 0), 0)
}


/**
 * Returns the first element in the array.
 */
export function first<T>(this: List<T>) {
  return this[0]
}

/**
 * Invokes iteratee for each element in the array, returning only those values
 * where iteratee returns a truthy value.
 * Like `filter`, except that it expands iteratee shorthand into full iteratee
 * functions.
 */
export function filterBy<T, I extends Iteratee<T, any>>(this: List<T>, iteratee: I): { [K in keyof T]: K extends I ? Truthy<T[K]> : T[K] }[] {
  const fn = _iteratee<T, any>(iteratee)
  return this.filter(fn) as any
}

/**
 * Combines map and filter in the same way flatMap combines map and flat.
 * Iterates over the array, invoking iteratee for each element, returning the
 * iteratee return values that are truthy.
 */
export function filterMap<T, P extends PropertyKey>(this: List<T>, iteratee: P): Array<Exclude<ExpandPath<T, P>, Falsy>>
export function filterMap<T, R>(this: List<T>, iteratee: Iteratee<T, R>): Array<Exclude<R, Falsy>>
export function filterMap<T>(this: List<T>, iteratee: any) {
  const fn = _iteratee(iteratee)

  const results: unknown[] = []

  this.forEach((item, index) => {
    const value = fn(item, index)

    if (value) {
      results.push(value)
    }
  })

  return results
}

/**
 * Like find, but first expands iteratee.
 */
export function findBy<T>(this: List<T>, iteratee: Iteratee<T, any>) {
  const fn = _iteratee<T, any>(iteratee)
  return this.find(fn)
}

/**
 * Combines map and find in the same way flatMap combines map and flat.
 * Iterates over the array, invokes `iteratee` for each element, and returns the
 * first iteratee value to be truthy.
 */
export function findMap<T, P extends PropertyKey>(this: List<T>, iteratee: P): Exclude<ExpandPath<T, P>, Falsy> | undefined
export function findMap<T, R>(this: List<T>, iteratee: IterateeFn<T, R>): Exclude<R, Falsy> | undefined
export function findMap<T, R>(this: List<T>, iteratee: any) {
  const fn = _iteratee(iteratee)

  for (const [index, item] of this.entries()) {
    const value = fn(item, index)

    if (value) {
      return value as Exclude<R, Falsy>
    }
  }
}

/**
 * Invokes iteratee for each element in the array, then uses the result as the
 * key in a new object.
 * The element will be added to an array stored at key's corresponding value.
 */
export function groupBy<T, P extends PropertyKey>(this: List<T>, iteratee: P): Record<ExpandPath<T, P> & PropertyKey, T[]>
export function groupBy<T, R>(this: List<T>, iteratee: IterateeFn<T, R>): Record<R & PropertyKey, T[]>
export function groupBy<T>(this: List<T>, iteratee: any) {
  return groupBy_(this, iteratee)
}

/**
 * Invokes iteratee for each element in the array, then uses the result as the
 * key in a new object.
 * The element will be added to an array stored at key's corresponding value.
 * Returns an array of [key, value[]].
 */
export function groupedEntries<T, P extends PropertyKey>(this: List<T>, iteratee: P): [ExpandPath<T, P> & PropertyKey, T[]][]
export function groupedEntries<T, R>(this: List<T>, iteratee: IterateeFn<T, R>): [R & PropertyKey, T[]][]
export function groupedEntries<T>(this: List<T>, iteratee: any) {
  return Object.typedEntries(groupBy_(this, iteratee))
}

/**
 * Invokes iteratee for each element in the array, then uses the result as the
 * key in a new object.
 * The element will be added to an array stored at key's corresponding value.
 */
export function groupedValues<T, P extends PropertyKey>(this: List<T>, iteratee: P): T[][]
export function groupedValues<T, R>(this: List<T>, iteratee: IterateeFn<T, R>): T[][]
export function groupedValues<T>(this: List<T>, iteratee: any) {
  return Object.values(groupBy_(this, iteratee))
}


/**
 * Inserts `itemToAdd` after `item` if `item` exists in `this`.
 * Returns `true` if the item was inserted, `false` otherwise.
 */
export function insertAfter<T>(this: T[], item: T, itemToAdd: T) {
  const position = this.indexOf(item)

  if (position > -1) {
    this.splice(position + 1, 0, itemToAdd)
    return true
  }

  return false
}

/**
 * Inserts `itemToAdd` before `item` if `item` exists in `this`.
 * Returns `true` if the item was inserted, `false` otherwise.
 */
export function insertBefore<T>(this: T[], item: T, itemToAdd: T) {
  const position = this.indexOf(item)

  if (position > -1) {
    this.splice(position, 0, itemToAdd)
    return true
  }

  return false
}

/**
 * Inserts value between each pair of values in the array. If valueOrFn is a function,
 * it will be invoked for each element (with the index as the first argument) and the
 * resulting value will be inserted.
 */
export function interpose<T, I>(this: List<T>, valueOrFn: I | ((index: number) => I)): Array<T | I> {
  return this
    .flatMap((item, index) => [item, invoke(valueOrFn, index)])
    .slice(0, -1)
}

/**
 * Like map, but first expands iteratee, then invokes each resulting function.
 */
export function invokeBy<T, P extends PropertyKey>(this: List<T>, path: P) {
  const fn = property_<T, ExpandPath<T, PropertyKey>>(path)

  return this.map(item => {
    const fn_ = fn as (<T2>(obj: T2) => ExpandPath<T2, P>)
    const result = fn_(item)
    return (result as any).call(item) as ReturnType<typeof fn_<T>> extends AnyFunction ? ReturnType<ReturnType<typeof fn_<T>>> : never
  })
}


/**
 * Returns all elements in the array until iteratee returns true.
 */
export function keepUntil<T, P extends PropertyKey>(this: List<T>, iteratee: P): Extract<T, { [K in P]: Falsy }>[]
export function keepUntil<T, R = any>(this: List<T>, iteratee: IterateeFn<T, R>): T[]
export function keepUntil<T>(this: List<T>, iteratee: any) {
  const index = is.propertyKey(iteratee) ?
    this.findIndex(item => (item as any)[iteratee]) :
    this.findIndex(iteratee)

  return index > -1 ?
    this.slice(0, index) :
    this.slice(0)
}

/**
 * Invokes iteratee for each element in the array, then uses the result as the
 * key in a new object, with the element itself used as the value.
 */
export function keyBy<T, P extends PropertyKey>(this: List<T>, iteratee: P): Record<ExpandPath<T, P> & PropertyKey, T>
export function keyBy<T, R = any>(this: List<T>, iteratee: IterateeFn<T, R>): Record<R & PropertyKey, T>
export function keyBy<T>(this: List<T>, iteratee: any) {
  const collection: Record<any, any> = {}

  const fn = _iteratee(iteratee)

  this.forEach((value, index) => {
    const key = fn(value, index)

    if (key != null && !(key in collection)) {
      collection[key] = value
    }
  })

  return collection
}

/**
 * Returns the last element in the array.
 */
export function last<T>(this: List<T>): T {
  return this[this.length - 1]
}

/**
 * Like map, but first expands iteratee.
 */
export function mapBy<T, P extends PropertyKey>(this: List<T>, iteratee: P): ExpandPath<T, P>[]
export function mapBy<T, R>(this: List<T>, iteratee: IterateeFn<T, R>): R[]
export function mapBy<T>(this: List<T>, iteratee: any) {
  const fn = _iteratee(iteratee)
  return this.map(fn)
}

/**
 * Returns the maximum value in the array.
 */
export function max<T extends number>(this: List<T>): number {
  return Math.max(...this)
}

/**
 * Invokes iteratee for each element in the array, then computes the maximum value.
 */
export function maxBy<T, P extends PropertyKey>(this: List<T>, iteratee: P): T | undefined
export function maxBy<T>(this: List<T>, iteratee: IterateeFn<T, any>): T | undefined
export function maxBy<T>(this: List<T>, iteratee: any) {
  return maxBy_(this, iteratee)
}

/**
 * Returns the minimum value in the array.
 */
export function min<T extends number>(this: List<T>): number {
  return Math.min(...this)
}

/**
 * Invokes iteratee for each element in the array, then computes the minimum value.
 */
 export function minBy<T, P extends PropertyKey>(this: List<T>, iteratee: P): T | undefined
 export function minBy<T>(this: List<T>, iteratee: IterateeFn<T, any>): T | undefined
 export function minBy<T>(this: List<T>, iteratee: any) {
  return minBy_(this, iteratee)
}

/**
 * Creates an array of elements by running each element through each iteratee.
 * You may Optionally a list of orders to sort by. If orders is unspecified, all
 * values are sorted in ascending order. Otherwise. specify 'desc' or 'asc' for
 * each corresponding value.
 */
export function orderBy<T>(this: List<T>, iteratees?: Iteratee<T, any> | Iteratee<T, any>[], orders?: boolean | 'asc' | 'desc' | ReadonlyArray<boolean | 'asc' | 'desc'>) {
  return orderBy_(this, iteratees, orders)
}

/**
 * Invokes iteratee for each element in the array, then splits the array into two,
 * of which the first contains truthy values, and the second contains falsey values.
 */
export function partition<T, U extends T>(this: List<T>, predicate: (item: T) => item is U): [U[], Exclude<T, U>[]]
export function partition<T>(this: List<T>, predicate: Iteratee<T, any>): [T[], T[]]
export function partition<T>(this: List<T>, predicate: Iteratee<T, any>) {
  return partition_(this, predicate) as any
}

/**
 * Gets the value at index. If index is negative, it will index from the end of
 * the array. Defaults to -1.
 */
export function peek<T>(this: List<T>, index = -1): T | undefined {
  if (index < 0) {
    index = this.length + index
  }

  return this[index]
}

/**
 * Same as `indexOf()`, but returns `null` instead of `-1`.
 */
export function positionOf<T>(this: List<T>, element: T | (TSReset.WidenLiteral<T> & {})): number | null {
  const index = this.indexOf(element)
  return index === -1 ? null : index
}

/**
 * Removes all instances of value from array. Mutates the array.
 */
export function pull<T, R extends List<any>>(this: R, value: T): R {
  return pull_(this, value) as R
}

/**
 * Filters the elements corresponding to `indexes` from the array.
 */
export function removeAt<T>(this: List<T>, ...indexes: Many<number>[]) {
  const set = new Set(indexes.flat())
  return this.filter((item, index) => !set.has(index))
}

/**
 * Returns a random element from the array.
 */
export function sample<T>(this: List<T>) {
  return sample_(this)
}

/**
 * Returns count random elements from the array.
 */
export function sampleSize<T>(this: List<T>, count: number) {
  return sampleSize_(this, count)
}

/**
 * Returns the elements of array in random order.
 */
export function shuffle<T>(this: List<T>) {
  return shuffle_(this)
}

/**
 * Returns an array sorted by iteratee.
 */
export function sortBy<T, K extends keyof T>(this: List<T>, ...iteratees: K[]): T[]
export function sortBy<T>(this: List<T>, ...iteratees: Iteratee<T, any>[] | Iteratee<T, any>[][]): T[]
export function sortBy<T>(this: List<T>, ...iteratees: Iteratee<T, any>[] | Iteratee<T, any>[][]): T[] {
  iteratees = iteratees.flat()
  return sortBy_(this, iteratees.length > 0 ? iteratees : [identity])
}

/**
 * Computes the sum of the values in array. Any null or undefined values are treated as 0.
 */
export function sum<T extends number>(this: List<T>) {
  return (this as T[]).reduce((total, value) => total + (value ?? 0), 0)
}

/**
 * Invokes iteratee for each element in the array and sums the resulting values.
 * Any null or undefined values are treated as 0.
 */
export function sumOf<T, P extends PropertyKey>(this: List<T>, iteratee: P): number
export function sumOf<T>(this: List<T>, iteratee: Iteratee<T, number>): number
export function sumOf<T>(this: List<T>, iteratee: any) {
  const fn = _iteratee<T, number>(iteratee)
  return (this as T[]).reduce((total, item, index) => total + (Number.castInt(fn(item, index)) ?? 0), 0)
}

/**
 * Transforms this array into an object.
 * When invoked without an iteratee, this is indentical to calling Object.fromEntries.
 * If invoked with an iteratee, array.filterMap(iteratee) will be called, and the
 * result will be passed into fromEntries.
 */
export function toObject<T extends [PropertyKey, unknown] | readonly [PropertyKey, unknown]>(this: List<T>): Record<T[0], T[1]>
export function toObject<T extends List<unknown>>(this: List<T>): Record<T[number] & PropertyKey, T[number]>
export function toObject<T, Pair extends [PropertyKey, any]>(this: List<T>, iteratee: (object: T, index: number) => Pair | null | undefined): Record<Pair[0], Pair[1]>
export function toObject<T extends List<unknown>>(this: List<T>, iteratee?: Iteratee<T, any>) {
  if (iteratee) {
    const entries = this.filterMap<any, any>(iteratee)
    return Object.fromEntries(entries)
  }

  return Object.fromEntries(this)
}

/**
 * Returns a set containing the items from this array.
 */
export function toSet<T>(this: List<T>): Set<T> {
  return new Set(this)
}

/**
 * Transforms an array of keys into a sort iteratee.
 */
export function toSortIteratee<T extends PropertyKey>(this: List<T>) {
  const positions = this.uniq().toObject((value, index) => [value, index])
  return (item: T) => positions[item]
}

/**
 * Assumes that this is an array of arrays and transposes the rows and columns.
 */
export function transpose<T>(this: List<T[]>) {
  return zip_(...this)
}

/**
 * Returns a new array with duplicate elements removed.
 */
export function uniq<T>(this: List<T>) {
  return uniq_(this)
}

/**
 * Invokes iteratee for each element in array, then returns a new array with
 * duplicates removed. The iteratee returnv value is used when determining which
 * elements are duplicates.
 */
export function uniqBy<T, P extends PropertyKey>(this: List<T>, iteratee: P): T[]
export function uniqBy<T, R>(this: List<T>, iteratee: IterateeFn<T, R>): T[]
export function uniqBy<T>(this: List<T>, iteratee: any) {
  return uniqBy_(this, iteratee)
}

/**
 * Returns a new array will all instances of value removed.
 */
export function without<T>(this: List<T>, ...values: T[]) {
  return without_(this, ...values)
}

/**
 * Creates an array of grouped elements, the first of which contains the first
 * elements of the given arrays, the second of which contains the second elements
 * of the given arrays, and so on.
 */
export function zip<T>(this: List<T>, ...arrays: any[]) {
  return zip_(this, ...arrays)
}

/**
 * Constructs a new object using an array of keys and values. This is identical
 * to lodash.zipObject, except that it accepts an optional iteratee when
 * constructing the object values.
 */
export function zipObject<T extends PropertyKey>(this: List<T>): Record<T, T>
export function zipObject<T extends PropertyKey, R>(this: List<T>, iteratee: List<R> | ((obj: T, index: number) => R)): Record<T, R>
export function zipObject<T extends PropertyKey, R>(this: List<T>, iteratee?: List<R> | ((obj: T, index: number) => R)) {
  if (typeof iteratee === 'function') {
    return zipObject_(this, this.map(iteratee))
  }

  return zipObject_(this, this)
}


declare global {
  interface Array<T> { // eslint-disable-line @typescript-eslint/no-unused-vars
    chunk: typeof chunk
    compact: typeof compact
    countOf: typeof countOf
    first: typeof first
    filterBy: typeof filterBy
    filterMap: typeof filterMap
    findBy: typeof findBy
    findMap: typeof findMap,
    groupBy: typeof groupBy,
    groupedEntries: typeof groupedEntries,
    groupedValues: typeof groupedValues,
    insertAfter: typeof insertAfter,
    insertBefore: typeof insertBefore,
    interpose: typeof interpose,
    invokeBy: typeof invokeBy,
    keepUntil: typeof keepUntil,
    keyBy: typeof keyBy,
    last: typeof last,
    mapBy: typeof mapBy
    max: typeof max
    maxBy: typeof maxBy
    min: typeof min
    minBy: typeof minBy
    orderBy: typeof orderBy
    partition: typeof partition
    peek: typeof peek
    positionOf: typeof positionOf
    pull: typeof pull
    removeAt: typeof removeAt
    sample: typeof sample
    sampleSize: typeof sampleSize
    shuffle: typeof shuffle
    sortBy: typeof sortBy
    sum: typeof sum
    sumOf: typeof sumOf
    toObject: typeof toObject
    toSet: typeof toSet
    toSortIteratee: typeof toSortIteratee
    transpose: typeof transpose
    uniq: typeof uniq
    uniqBy: typeof uniqBy
    without: typeof without
    zip: typeof zip
    zipObject: typeof zipObject
  }

  interface ReadonlyArray<T> { // eslint-disable-line @typescript-eslint/no-unused-vars
    chunk: typeof chunk
    compact: typeof compact
    countOf: typeof countOf
    first: typeof first
    filterBy: typeof filterBy
    filterMap: typeof filterMap
    findBy: typeof findBy
    findMap: typeof findMap,
    groupBy: typeof groupBy,
    groupedEntries: typeof groupedEntries,
    groupedValues: typeof groupedValues,
    interpose: typeof interpose,
    invokeBy: typeof invokeBy,
    keepUntil: typeof keepUntil,
    keyBy: typeof keyBy,
    last: typeof last,
    mapBy: typeof mapBy
    max: typeof max
    maxBy: typeof maxBy
    min: typeof min
    minBy: typeof minBy
    orderBy: typeof orderBy
    partition: typeof partition
    peek: typeof peek
    positionOf: typeof positionOf
    pull: typeof pull
    removeAt: typeof removeAt
    sample: typeof sample
    sampleSize: typeof sampleSize
    shuffle: typeof shuffle
    sortBy: typeof sortBy
    sum: typeof sum
    sumOf: typeof sumOf
    toObject: typeof toObject
    toSet: typeof toSet
    toSortIteratee: typeof toSortIteratee
    transpose: typeof transpose
    uniq: typeof uniq
    uniqBy: typeof uniqBy
    without: typeof without
    zip: typeof zip
    zipObject: typeof zipObject
  }
}
