import './global.js'
import CachingRegistry from './server/context/cache/CachingRegistry.js'

const initialized = Symbol('global')
const context = globalThis as typeof globalThis & { [initialized]?: boolean }

export function initializeEnvironment() {
  if (!context[initialized]) {
    context[initialized] = true

    if (__sentryDsn__) {
      Promise.all([
        import('./client/sentry/config.js'),
        import('./client/sentry/initialize.js'),
      ])
        .then(([config, sentry]) => sentry.initialize(config.default))
        .catch(noop)
    }

    if ('dx' in globalThis) {
      dx.set('caching', new CachingRegistry(), noop)
    }

    if (__development__ && import.meta.hot) {
      // Vite imports this module 200+ times unless we truncate the timestamp
      import.meta.hot.on('vite:beforeUpdate', event => {
        if (event.type === 'update') {
          event.updates.forEach(update => {
            if ((update.type === 'js-update' || update.type === 'css-update') && update.path.includes('/styles.css')) {
              update.timestamp = Math.floor(update.timestamp / 10000) * 10000
            }
          })
        }
      })
    }
  }
}

initializeEnvironment()