/*
To improve developer ergonomics, this app takes the step of polyfilling some TC39
proposals and extending some native objects like `Array.prototype`, `Object`,
and `Promise`. These extensions provide functionality that would be part of the
standard library in any other language and prevent _many_ files from being
littered with dozens of import statements.

Precautions are taken to never replace any property already defined on one of
these objects. If any conflicts are detected, an error is thrown.
*/
import extensions from './extensions/index.js'

  extensions.forEach(([object, methods]) => {
    Object.entries(methods).forEach(([name, fn]) => {
      try {
        if (name in object) {
          // logError(object, name, 'Method already defined.')
          return
        }

        Object.defineProperty(object, name, { value: fn })
        
      } catch (error) {
        logError(object, name, error)
      }
    })
  })

function logError(object: object, name: string, error: unknown) {
  if (import.meta.env?.DEV) {
    const description = getObjectDescription(object)
    dx.capture(new Error(`Could not add extension '${description}.${name}'`, { cause: error }))
  }
}

function getObjectDescription(object: object) {
  return (
    ('name' in object && object.name as string) ||
    (object.constructor && `${object.constructor.name}.prototype`) ||
    String(object) ||
    'unknown'
  )
}
