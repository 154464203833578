import {
  produce as _produce,
  createDraft,
  finishDraft,
  enableMapSet,
  enablePatches,
  type Draft,
} from 'immer'

enableMapSet()
enablePatches()

type Objectish = Record<string, any> | Array<any> | Set<any> | Map<any, any>


const produce = _produce as typeof _produce & { async: typeof produceAsync }
produce.async = produceAsync

export default produce


async function produceAsync<State>(state: State, recipe: (draft: Draft<State>) => undefined | void | Draft<State> | Promise<undefined | void | Draft<State>>) {
  const draft = createDraft(state as Objectish) as Draft<State>
  await recipe(draft)
  return finishDraft(draft)
}
