import './global/extensions.js'
import dx_ from './global/dx/index.js'
import { mutable } from './global/dx/core.js'
import defineFunctions from './global/functions.js'

defineFunctions()

Object.defineProperty(globalThis, 'dx', { value: dx_, configurable: true })

if ('__dxFlags' in globalThis) {
  mutable.flags!.addAll(...((globalThis as any).__dxFlags ?? []) as string[])
}