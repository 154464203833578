import { rootName } from './constants.js'
import getCachingSource from './getCachingSource.js'
import purge from './purge.js'
import type {
  CachingInitiator,
  CachingSource,
} from './types.js'


let lastId = 0


export type CachingEntryAttributes = {
  path?: string
  source?: CachingSource
  ttl?: Nullable<number> | false
  etag?: Nullable<string> | false
  expires?: Nullable<number> | false
  initiator: CachingInitiator
}

export type CachingEntryParseOptions = {
  header: Nullable<string>
  url: RequestInfo | URL | false
  source?: CachingSource
  initiator?: CachingInitiator
}

export default class CachingEntry {
  id: number = ++lastId
  path: string
  cached: boolean
  ttl: number | null
  etag: string | null
  expires: number | null
  source: CachingSource
  initiator: CachingInitiator

  constructor(attributes: CachingEntryAttributes) {
    const {
      path,
      source,
      ttl,
      etag,
      expires,
      initiator,
    } = attributes

    this.path = path ?? rootName
    this.cached = Boolean(ttl || etag)
    this.ttl = ttl || null
    this.etag = etag || null
    this.expires = expires || (ttl ? Date.now() + (ttl * 1_000) : null)
    this.source = source ?? getCachingSource(this.path)
    this.initiator = initiator
  }

  clear() {
    this.ttl = null
    this.etag = null
    this.expires = null
    this.cached = false
  }

  isRoot() {
    return this.path === rootName
  }

  get fullPath() {
    return this.isRoot() ?
      window.location.pathname :
      this.path
  }

  get fullSource() {
    return this.isRoot() ?
      'ssr' :
      this.source
  }

  async purge() {
    await purge(this.fullPath)
    this.clear()
  }
}
