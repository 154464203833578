import type { CachingSource } from './types.js'
import { rootName } from './constants.js'

export default function getCachingSource(path: string): CachingSource {
  if (/^https?:\/\//.test(path)) {
    return 'external'
  }

  if (path === rootName) {
    return 'root'
  }

  if (path.startsWith('/api/')) {
    return 'api'
  }

  if (path.startsWith('/')) {
    return 'ssr'
  }

  return 'unknown'
}
