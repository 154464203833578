import {
  camelCase as camelCase_,
  capitalize as capitalize_,
  deburr as deburr_,
  kebabCase as kebabCase_,
  lowerFirst as lowerFirst_,
  snakeCase as snakeCase_,
  startCase as startCase_,
  upperFirst as upperFirst_,
} from 'lodash-es'

import {
  scan as scan_,
  replaceToArray as replaceToArray_,
} from '#/string/index'

import type { DelimiterCase } from 'type-fest'
// import pluralize_ from 'pluralize'

/**
 * Converts the first character of string to uppercase and the rest to lowercase.
 */
export function capitalize<T extends string>(this: T) {
  return capitalize_(this) as Capitalize<Lowercase<T>>
}

/**
 * Converts the string to use basic Latin letters without diacritical marks.
 */
export function deburr(this: string) {
  return deburr_(this)
}

/**
 * Executes `fn` for each line in this string, then joins the lines back together.
 */

export function editLines(this: string, fn: (line: string, index: number) => string | false | Nullish) {
  return this
    .lines()
    .map(fn)
    .filter(l => l != null && l !== false)
    .join('\n')
}


/**
 * Returns this string split into an array of lines.
 */
export function lines(this: string) {
  return this.split(/\r?\n/)
}

/**
 * Calls String.match and returns only the named capture groups.
 * The entire match is available on the `_` property.
 */
export function matchGroups(this: string, pattern: RegExp) {
  pattern.lastIndex = 0
  const match = this.match(pattern)

  return match ?
    { ...match?.groups, _: match?.[0] } as Record<string, string> :
    null
}

// /**
//  * Returns true if the string is plural.
//  */
// export function isPlural(this: string) {
//   return pluralize_.isPlural(this)
// }

// /**
//  * Returns true if the string is singular.
//  */
// export function isSingular(this: string) {
//   return pluralize_.isSingular(this)
// }

// /**
//  * Pluralizes the string.
//  */
// export function pluralize(this: string, count?: number, includeNumber?: boolean) {
//   return pluralize_(this, count, includeNumber)
// }


/**
 * Like replace, but provides the named capture groups as the first argument.
 */
export function replaceGroups(this: string, pattern: RegExp, replacer: ((groups: Record<string, string>, match: string, input: string) => string)): string {
  const self = this
  pattern.lastIndex = 0

  return this.replace(pattern, (match, ...args) => {
    const last = args.last()
    const groups = Object.isObject(last) ? last : {}

    const proxy = new Proxy(groups, {
      get(target, property) {
        if (Reflect.has(target, property)) {
          return Reflect.get(target, property)
        }

        throw new Error(`No capture group named '${String(property)}'`)
      },
    })

    return replacer(proxy, match, self)
  })
}


/**
 * Like replace, but returns both unreplaced and replaced segments of the string
 * as an array. If a function is given as replacer, it is invoked for each match
 * with the match spread as arguments.
 */
export function replaceToArray<T>(this: string, pattern: RegExp, replacer: T | ((match: string, key: string, groups: Record<string, string>, input: string) => T)): Array<T | string> {
  return replaceToArray_(this, pattern, replacer)
}

/**
 * A more useful String.matchAll.
 * Repeatedly matches pattern against the string and returns the matches as an
 * array. If the pattern has capture groups, the array will contain one array per
 * match containing each group. If groupIndex is given, it will return an array
 * containing only that capture group.
 */
export function scan(this: string, pattern: Parameters<typeof scan_>[1], groupIndex?: Parameters<typeof scan_>[2]) {
  return scan_(this, pattern, groupIndex)
}

/**
 * Lowercases, trims, and deburrs string. Useful when doing loose comparisons
 * between external string values.
 */
export function simplify(this: string) {
  return deburr_(this.trim().toLowerCase())
}

// /**
//  * Singularizes the string.
//  */
// export function singularize(this: string) {
//   return pluralize_.singular(this)
// }

/**
 * Converts the string to camel case.
 */
export function toCamelCase<T extends string>(this: T) {
  return camelCase_(this) as CamelCase<T>
}

/**
 * Converts the string to kebab case.
 */
export function toKebabCase<T extends string>(this: T) {
  return kebabCase_(this) as KebabCase<T>
}

/**
 * Converts the first character of string to lower case.
 */
export function toLowerFirst<T extends string>(this: T) {
  return lowerFirst_(this) as Uncapitalize<T>
}


/**
 * Converts the string to snake case.
 */
export function toSnakeCase<T extends string>(this: T) {
  return snakeCase_(this) as SnakeCase<T>
}

/**
 * Converts the string to start case.
 */
export function toStartCase<T extends string>(this: T) {
  return startCase_(this.toLowerCase())
}

/**
 * Converts the first character of string to upper case.
 */
export function toUpperFirst<T extends string>(this: T) {
  return upperFirst_(this) as Capitalize<T>
}

/**
 * Like kebabCase, but hypens are replaced with spaces.
 */
export function toWordCase<T extends string>(this: T) {
  return kebabCase_(this).replace(/-/g, ' ') as DelimiterCase<T, ' '>
}

declare global {
  interface String {
    capitalize: typeof capitalize,
    deburr: typeof deburr
    // isPlural: typeof isPlural
    // isSingular: typeof isSingular
    lines: typeof lines
    matchGroups: typeof matchGroups
    // pluralize: typeof pluralize
    // singularize: typeof singularize
    replaceToArray: typeof replaceToArray
    replaceGroups: typeof replaceGroups
    editLines: typeof editLines
    scan: typeof scan
    simplify: typeof simplify
    toCamelCase: typeof toCamelCase
    toKebabCase: typeof toKebabCase
    toLowerFirst: typeof toLowerFirst
    toSnakeCase: typeof toSnakeCase
    toStartCase: typeof toStartCase
    toUpperFirst: typeof toUpperFirst
    toWordCase: typeof toWordCase

    startsWith<T extends string>(searchString: T, position?: 0): this is `${T}${string}`
    endsWith<T extends string>(searchString: T, endPosition?: undefined): this is `${string}${T}`
  }
}