/**
 * Like String.replace, but returns both unreplaced and replaced segments of the
 * string as an array. If a function is given as replacer, it is invoked
 * for each match with the match spread as arguments.
 *
 * This is particuarly useful in React when you want to replace or wrap portions
 * of a string with a component.
 *
 * @example
 * replaceToArray('foo: 123, bar: 234', /[a-z]+/g, 'baz') // ['baz', ': 123, ', 'baz', ': 234']
 * replaceToArray('foo: 123, bar: 234', /\d+/g, m => `**${m}**`) // ['foo: ', '**123**', ', bar: ', '**234**']
 * replaceToArray('foo: 123, bar: 234', /[a-z]+/g, (m, i) => <b key={i}>{m}</b>) // [<span key={1}>foo</span>, ': 123, ', <span key={1}>bar</span>, ': 234']
 */
export function replaceToArray<T>(string: string, pattern: RegExp, replace: T | ((match: string, key: string, groups: Record<string, string>, input: string) => T)): Array<T | string> {
  const output: Array<T | string> = []

  pattern.lastIndex = 0

  let match: RegExpExecArray | null = null
  let index: number
  let count = 0
  let prevIndex = 0

  while ((match = pattern.exec(string))) {
    index = match.index

    if (match[0] === '') {
      prevIndex++
    }

    if (index !== prevIndex) {
      output.push(string.substring(prevIndex, index))
    }

    prevIndex = index + match[0].length

    output.push(invoke(replace, match[0], String(count++), match.groups ?? {}, match.input))

    if (!pattern.global) {
      break
    }
  }

  if (prevIndex < string.length) {
    output.push(string.substring(prevIndex))
  }

  return output
}
