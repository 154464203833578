import loader from '#/loader'

export default async function purge(path?: string) {
  const [load] = loader<Dx.Api.Dev.Cache.Purge.In, EmptyObject>('/api/dev/cache/purge', {
    method: 'post',
    body: Object.compact({
      path,
    }),
  })

  await load()
}
