import {
  range as range_,
  times as times_,
} from 'lodash-es'

/**
 * Converts a value to a useful float. Instead of returning NaN or Infinity for
 * invalid values, like parseFloat(), this returns `fallback`.
 */
export function castFloat(value: any, fallback: number): number
export function castFloat(value: any, fallback?: null): number | null
export function castFloat(value: any, fallback: number | null = null) {
  const output = parseFloat(value)
  if (isNaN(output) || !isFinite(output)) return fallback
  return output
}

/**
 * Converts a value to a useful integer. Instead of returning NaN for invalid
 * values, like parseInt(), this returns `fallback`.
 */
export function castInt(value: any, fallback: number): number
export function castInt(value: any, fallback?: null): number | null
export function castInt(value: any, fallback: number | null = null) {
  const output = parseInt(value)
  if (isNaN(output) || !isFinite(output)) return fallback
  return output
}

/**
 * Converts a value to a useful integer, but does so exactly. For example,
 * parseInt/castInt would return `123` when parsing '123abc', but this function
 * would instead recognize this as an invalid value. Instead of returning NaN
 * for invalid values, like parseInt(), this returns `fallback`.
 */
export function castIntExact(value: any, fallback: number): number
export function castIntExact(value: any, fallback?: null): number | null
export function castIntExact(value: any, fallback: number | null = null) {
  const output = Number(value)
  if (isNaN(output) || !isFinite(output)) return fallback
  return output
}

/**
 * Creates an array of sequential progressing from `start` up to, but not including,
 * `end`. A `step` of -1 is used if a negative `start` is specified without an `end`
 * or `step`. If `end` is not specified, it is set to `start` with `start` then
 * set to 0.
 */
export const range = range_

/**
 * Invokes the iteratee number times, returning an array of the results.
 */
export const times = times_

declare global {
  interface NumberConstructor {
    castFloat: typeof castFloat
    castInt: typeof castInt
    castIntExact: typeof castIntExact
    range: typeof range
    times: typeof times
  }
}